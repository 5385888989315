import * as THREE from "three";
import colors from "./colors";

// use MeshLambertMaterial for better performance, but no shadows
const Material = THREE.MeshPhongMaterial;

export const createSea = () => {
  // radius top, radius bottom, height, number of segments on the radius, number of segments vertically
  const geometry = new THREE.CylinderGeometry(800, 800, 800, 40, 15);
  const rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2);
  geometry.applyMatrix(rotation);
  geometry.mergeVertices();

  // must do rotation before getting vertices!!
  const waves = geometry.vertices.map(({ x, y, z }) => ({
    x,
    y,
    z,
    angle: Math.random() * Math.PI * 2,
    amplitude: 5 + Math.random() * 35,
    speed: 0.016 + Math.random() * 0.01
  }));

  const material = new Material({
    color: colors.white,
    transparent: true,
    opacity: 0.7,
    flatShading: true
  });

  const sea = new THREE.Mesh(geometry, material);
  sea.receiveShadow = true;
  return { sea, waves };
};

const setEuler = (attribute, mesh, { x, y, z }) => {
  if (x) mesh[attribute].x = x;
  if (y) mesh[attribute].y = y;
  if (z) mesh[attribute].z = z;
};
