import * as THREE from "three";

import { getDimensions } from "./dimensions";

export const create = () => {
  const { HEIGHT, WIDTH, ASPECT_RATIO } = getDimensions();

  // setup scene
  const scene = new THREE.Scene();
  scene.fog = new THREE.Fog(0xdedede, -100, 10000);

  const fov = 80;
  const nearPlane = 1;
  const farPlane = 10000;
  const camera = new THREE.PerspectiveCamera(
    fov,
    ASPECT_RATIO,
    nearPlane,
    farPlane
  );
  camera.position.x = 0;
  camera.position.z = 200;
  camera.position.y = 500;
  camera.rotateX(-0.7);

  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true
  });
  renderer.setSize(WIDTH, HEIGHT);
  renderer.shadowMap.enabled = true;

  return { scene, renderer, camera };
};
