import "styles/index.scss";

import { applyDimensions } from "./dimensions";
import { create as createScene } from "./scene";
import { create as createLight } from "./lights";
import { createSea } from "./objects";
import { twist } from "./animators";

const setProperty = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("resize", () => {
  setProperty();
});

setProperty();

setTimeout(() => {
  const ml = document.getElementById("email");
  const m = "YUdWc2JHOUFkM0poYzNObExtTnY=";
  const a = atob(atob(m));
  const mt = atob(atob("YldGcGJIUnZPZz09"));
  ml.href = `${mt}${a}`;
  ml.innerText = a;
}, 200);

const { scene, renderer, camera } = createScene();
const { shadowLight, hemisphereLight, ambientLight } = createLight();
scene.add(hemisphereLight);
scene.add(shadowLight);
scene.add(ambientLight);

const container = document.getElementById("world");
container.appendChild(renderer.domElement);
window.addEventListener(
  "resize",
  () => applyDimensions({ renderer, camera }),
  false
);

const { sea, waves } = createSea();
sea.position.y = -700;
scene.add(sea);

const loop = () => {
  sea.rotation.z += 0.003;
  twist({ mesh: sea, waves });

  renderer.render(scene, camera);
  requestAnimationFrame(loop);
};

loop();
