export const getDimensions = () => {
  const container = document.getElementById('world');
  const { height: HEIGHT, width: WIDTH } = container.getBoundingClientRect();
  // const { innerHeight: HEIGHT, innerWidth: WIDTH } = window;
  const ASPECT_RATIO = WIDTH / HEIGHT;
  return { HEIGHT, WIDTH, ASPECT_RATIO };
};

export const applyDimensions = ({ renderer, camera }) => {
  const { HEIGHT, WIDTH, ASPECT_RATIO } = getDimensions();
  renderer.setSize(WIDTH, HEIGHT);
  camera.aspect = ASPECT_RATIO;
  camera.updateProjectionMatrix();
};
